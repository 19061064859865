import React from 'react'

export default ({ toggleRwd }) => (
  <footer className="pt-5 border-top border-dark"
          id="footer">
    <div className="container d-lg-block">
      <div className="row mb-5">
        <div className="col-md-3 text-md-left text-center">
          <h4>
            <span className="mb-4 font-weight-bold text-primary"
               href="#">asdloop
            </span>
          </h4>
          <div>
            <p className="text-dark">Somos una agencia de desarrollo web
              especializada en Drupal con más de 8 años de experiencia.</p>
          </div>
        </div>
        <div className="col-md-3 text-center mb-4 mb-md-0">
          <a className="d-block text-dark" href="/servicios-drupal">Servicios</a>
          <a className="d-block text-dark" href="/proyectos-drupal">Proyectos</a>
          <a className="d-block text-dark" href="/equipo-especialistas-drupal">Nosotros</a>
        </div>
        <div className="col-md-3 text-center mb-4 mb-md-0">
          <a className="d-block text-dark" href="/aviso-legal">Aviso legal</a>
          <a className="d-block text-dark" href="/politica-de-privacidad">Política de
            privacidad</a>
          <a className="d-block text-dark" href="/politica-de-cookies">Política de
            cookies</a>
        </div>
        <div className="col-md-3 text-center mb-4 mb-md-0">
          <a className="btn btn-primary" href="/contacta">Contacta con
            nosotros</a>
        </div>
      </div>
      <div className="row w-100 justify-content-between text-dark">
        <div className="col text-center">
          <p>
            <small>© 2020 asdloop</small>
          </p>
        </div>
      </div>
    </div>
  </footer>
)
