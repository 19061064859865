import React from 'react'
import Header from './header'
import Footer from './footer'
import { Helmet } from "react-helmet"

import './../styles/styles.scss'

class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rwd_open: false
    }

    this.toggleRWD = this.toggleRWD.bind(this);
  }

  toggleRWD () {
    this.setState(prevState => ({
      rwd_open: !prevState.rwd_open
    }))
  }

  render() {
    const { children } = this.props
    const rwd_class = this.state.rwd_open ? 'rwd-open' : 'rwd-close'

    return (
      <div className={`page-container ${rwd_class}`}>
        <Helmet>
          <link href="//fonts.googleapis.com/css?family=Work+Sans:300,400,600&display=swap" rel="stylesheet" />
          <link href="//use.fontawesome.com/releases/v5.2.0/css/all.css" rel="stylesheet" />
        </Helmet>
        <Header toggleRwd={this.toggleRWD} />
        {children}
        <Footer />
      </div>
    )
  }
}

export default Template
