import React, { useState } from 'react'
import {Link} from 'gatsby'


export default ({ toggleRwd }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(isMenuOpen => !isMenuOpen)
  }

  return (
    <nav
      className="topnav navbar navbar-expand-lg bg-white"
      id="navbar"
    >
      <div className="container">
        <a className="navbar-brand text-primary" href="/">
          <img
            className="img-fluid"
            src={"/images/logo-asdloop.svg"}
            width={150}
            alt="Logo asdloop"
          />
        </a>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarColor02"
          aria-controls="navbarColor02"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleMenu}
        >
          <i className="fas fa-bars text-primary"/>
        </button>
        <div className={"navbar-collapse collapse" + (isMenuOpen ? ' show' : ' hidden')} id="navbarColor02">
          <ul className="navbar-nav mr-auto d-flex align-items-center"/>
          <ul className="navbar-nav ml-auto d-flex align-items-center">
            <li className="nav-item">
              <Link className="nav-link px-3" to="/proyectos-drupal">
                Proyectos
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-3" to="/servicios-drupal">
                Servicios
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-3" to="/equipo-especialistas-drupal">
                Nosotros
              </Link>
            </li>
            <li className="nav-item">
              <Link className="btn btn-primary " to="/contacta">
                Contacta
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
